@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  padding-left: calc(1/3 * 100vw);
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0;
  background-color: #f1f1f1;  
}

section {
  display: inline-flex;  
  width: 100%;
  /* padding-left: 1.5em;   */
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

h1, h3, p {
  color: rgb(141, 141, 141);
}

hr {
  height: 2px;
  border: none;
  color: #e7e7e7;
  background-color: #e7e7e7;
}

input, textarea {
  padding: 0.25em 0.75em;  
  border-radius: 15px;
  /* background-color: rgba(255, 255, 255, 0.5); */
  background-color: rgba(255, 255, 255, 0.8);
}

label {
  /* color: #fff; */
  font-weight: 600;
  letter-spacing: 2px;
  /* color: #fff; */
}

textarea {
  min-height: 300px;  
  overflow-y: auto;
  padding-top: 10px;
}

a:hover {
  cursor: pointer;
}

a span::after {
  content: '';
  position: absolute;  
}

.app-main {
  display: flex;
  width: 100%;  
  background: #fff;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.app-container {
  position: relative;
}

.content-container {
  padding-left: calc(1/3 * 100vw);  
}

.hidden {
  display: none;
}

/* NAVBAR */

.navbar {  
  font-family: 'Lexend', sans-serif;
  font-size: 12px;
  background: linear-gradient(to left, #eeeeee, #f3f3f3, transparent);  
  opacity: 0;
  transform: translateX(-5%);
  transition: 0.3s ease-in;
}

.navbar.visible {
  opacity: 1;
  transform: translateX(0);
}

.nav-item {    
  transform: translateX(25%);
  transition: 0.2s ease-in-out;
  transition-delay: 25ms;
}

.nav-item.active {
  font-size: 50px;
  font-weight: bold;
}

.nav-dot {
  display: flex;
  font-weight: bold;
  font-size: 50px;
}

.nav-item.visible {
  opacity: 1;
  transform: translateX(0);
}

.nav-logo {  
  width: 8%;
}

.social-icon {
  font-size: 15px;  
}

.linkedin, .github, .behance {
  opacity: 0;
  transform: translateY(35%);
  transition: 0.3s ease-in;  
}

.linkedin.visible, .github.visible, .behance.visible {
  opacity: 1;
  transform: translateY(0);  
}

.linkedin {
  transition-delay: 0.2s;
}

.github {
  transition-delay: 0.4s;
}

.behance {
  transition-delay: 0.6s;
}

/* HOME */

#home {    
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

#home p {
  color: #fff;
}

#home.active {
  opacity: 1;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  /* background-color: #f5f5f5; */
}

.home-btn {
  display: none;
  transition: 0.3s ease-in;
  opacity: 0;  
}

.home-btn.hovered {
  display: flex;
  opacity: 1;
  /* opacity: 1; */
}

/* .home-btn {
  color: rgba(0, 157, 147);
  padding: 1em;
  border: 1px solid transparent;  
  transition: border-color 0.3s ease;
} */

.home-photo {
  display: none;
}

/* .home-btn:hover {
  border-color: rgba(0, 157, 147);
  padding: 1em;
  color: rgba(141, 141, 141);
  transition: border 0.3s ease;  
} */

.greeting {
  opacity: 0;
  transform: translateX(10%);
  transition: 0.3s ease-in;
  margin-top: -22px;
  letter-spacing: 5px;
}

.text-content {
  display: flex;
  position: absolute;
  flex-direction: column;
  right: 0;
  top: 0;
  margin-top: 40px;
  margin-right: -40px;
  text-decoration: underline;
  text-underline-offset: 10px;
  transform: translateY(150%) rotate(90deg);  
}

.full-name {
  font-family: 'Figtree', sans-serif;
  font-weight: 800;
  opacity: 0;
  transform: translateX(-5%);
  transition: 0.3s ease-in;
  letter-spacing: 3px;
}

.comma {
  font-family: 'Figtree', sans-serif;
  opacity: 0;
  transform: translateY(-5%);
  transition: 0.3s ease-in;
  transition-delay: 0.3s;
}

.title-location {
  opacity: 0;
  transition: 0.3s ease-in;  
}

.title, .location {
  font-family: 'Figtree', sans-serif;
  font-weight: 600;
  letter-spacing: .2rem;
  opacity: 0;
  transform: translateY(-35%);
  transition: 0.3s ease-in;
  transition-delay: 2s;
}

.title.visible, .location.visible {
  opacity: 1;  
}

.greeting.visible, .full-name.visible, .comma.visible, .title-location.visible {
  opacity: 1;
  transform: translate(0);
}

.img-section {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.intro-img {  
  width: 60%;
  margin-right: -100px;
  height: auto;
  display: block;
  object-fit: cover;
  float: right;
}

.intro-overlay {
  position: absolute;
  top: 50%;
  right: 0;
  /* width: 95%;
  height: 45%; */
  background: linear-gradient(140deg, transparent, rgba(0, 157, 147, 0.3), rgba(0, 157, 147, 0.5));
  z-index: 1;
  /* border-radius: 5px; */
  border: 1px solid rgba(0, 157, 147, 0.8);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  animation: expandOverlay 1s forwards;
}

.arrow-container {
  background-color: rgba(0, 0, 0, 1);
  color: rgb(0, 180, 168);
  width: 75px;
  height: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  opacity: 0;
  /* border-radius: 3px; */
  transition: 0.3s ease-in;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.arrow-container.visible {
  opacity: 1;
}

.arrow-container:hover {
  width: 30%;
  transition: 0.15s ease-in;
  color: #fff !important;
  justify-content: flex-end;
  padding-right: 20px;
}

.home-arrow {
  position: relative;
  padding: 5px;
  font-size: 30px;
}

/* ABOUT */

#about {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  padding-left: 2em;
  font-family: 'Figtree', sans-serif;
}

#about.active {
  opacity: 1;
}

.about-me-pic {
  display: flex;
  height: auto;
  width: 80%;
  bottom: 0;
  right: 10vw;
  position: absolute;
  z-index: -100;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  transition-delay: 0.7s;
}

.about-me-pic.visible {
  opacity: 0.8;
}

.mobile-pic-container {
  display: none;  
}

.mobile-pic {
  display: none;
}

.about-section {
  display: flex;
  justify-content: center;
}

.about-overlay {
  /* position: absolute;   */
  /* width: 30%; */
  /* height: 100%;   */
  background: linear-gradient(25deg, rgba(0, 157, 147, 0.3), rgba(0, 157, 147, 0.2), transparent);
  z-index: 1;  
  /* border-radius: 5px; */
  border: 1px solid rgba(0, 157, 147, 0.8);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.about-header {
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  text-decoration: underline;
}

/* PROJECTS */

#projects {  
  display: flex;  
  padding: 1em;
  /* opacity: 0;
  transition: opacity 0.3s ease-in-out;   */
}

/* #project.active {
  opacity: 1;
} */

.project-card-group {  
  width: 100vw;
  padding: 1em;
  overflow-y: auto;
  overflow-x: hidden;
}

.project-card {  
  border: 1px solid #c7c7c7;  
  border-radius: 10px;
  position: relative;  
}

.project-list {  
  position: absolute;
  flex-direction: column;    
  text-align: right;  
  right: 0;
}

.project {
  font-family: 'Figtree', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 1);
  margin: 1em;
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transform: translateY(20px);
}

.project.visible {
  opacity: 1;
  transform: translateY(0)
}

.project.hidden {  
  transform: translateY(20px);
  transition: transform 0.5s ease-out;
}

.project.selected {  
  opacity: 0;  
}

.project:hover {
  cursor: pointer;
}

.project-display {
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;  
}

.project-display-container {
  position: relative;
  overflow: auto;
}

.project-display.visible {
  opacity: 1;
}

.project-logo-container {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.project-logo {
  transition: opacity 0.3s ease-in, transform 0.3s ease-in;  
  opacity: 0;
  width: 40%;
  transform: translateY(-25%);
}

.project-logo.visible {
  opacity: 1;
  transform: translateY(0);
}

.project-description, .project-category, .demo-link {
  font-family: 'Figtree', sans-serif;
  opacity: 0;
  transform: translateY(-2%);
  transition: 0.3s ease-in;
}

.project-category.visible {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.3s;
}

.project-description.visible {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.45s;
}

.demo-link.visible {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.57s;
}

.back-btn {
  position: absolute;
  right: 5px;
  display: inline-flex;
  align-items: center;
  opacity: 0;
  transform: translateX(50%);
  transition: 0.3s ease-in;
}

.back-btn.visible {
  opacity: 1;
  transform: translateX(0%);
}

.back-arrow {
  transform: translateX(60px);
  transition: 0.3s ease-in-out;
  color:rgb(0, 157, 147);
  font-size: 25px;
}

.back-arrow.hovered {
  transform: translateX(-20%);
}

.back-text {
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.back-text.hovered {
  opacity: 1;
}

.feature-img-overlay {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255);
  transition: opacity 0.3s ease;
}

.project-card:hover .feature-img-overlay {
  opacity: 1;
  cursor: pointer;
}

.project-name {
  opacity: 0;
  color: rgba(0, 157, 147);
  transition: opacity 0.3s ease;
}

.project-card:hover .project-name {
  opacity: 1
}

.projects-header {
  width: 100%;
  text-align: center;
  border: 2px solid rgb(0, 157, 147);
  border-radius: 15px;
  padding: 0.5em;
  font-weight: 500;
}

.highlight {
  color: rgb(0, 157, 147);
}

.full-name {
  color: rgba(0, 0, 0, 1);
}

.title, .location, .sub-highlight, .active {
  color: rgba(0, 157, 147, 0.65);
  /* color: rgba(255, 255, 255, 0.8); */
  /* color: rgba(0, 0, 0, 0.6); */
  transition: ease-in 0.3s;
}

.active {
  color: rgba(0, 0, 0, 1)
}

.inactive:hover {
  transition: ease-in 0.2s;
  color: #5f5f5f;
  font-size: 14px;  
}

.active .nav-indicator:before {
  opacity: 0;
}

.fade-in, .tech-intro, .about-header {
  opacity: 0;
  transform: translateY(5px);
  transition: opacity 1s ease-in-out, transform 0.3s ease-in-out;  
}

.fade-in.visible, .tech-intro.visible, .about-header.visible {
  opacity: 1;
  transform: translateY(0);
}

.icon {
  font-size: 25px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
  width: 75vw;
  height: 75vh;
}

.tech {
  align-items: center;
  opacity: 0;
  transform: translateY(20%);
  transition: 0.3s ease-in;
}

.tech.visible {
  opacity: 1;
  transform: translateY(0);
}

.project-images {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;  
  margin-top: 10px;  
  overflow-y: auto;
  opacity: 0;
  transition: 0.3s ease-in;
}

.project-images.visible {
  opacity: 1;
}

.project-images img {
  max-width: 100%;
}

.intro {
  z-index: 2;
}

.intro-pic {
  z-index: 1;
}

/* CONTACT  */

#contact {  
  position: fixed;
  bottom: 2.75em;
  right: 2em;
  z-index: 1000000;
  opacity: 0;
  transform: translateY(35%);
  transition: 0.3s ease-in;
}

#contact.visible {
  opacity: 1;
  transform: translateY(0);
}

.chat-bubble {
  transition: ease-in-out 0.3s;
  font-size: 50px;
  border-radius: 100px;
  color: #00ab41;
  background-color: #fff;  
  float: right;
}

.chat-bubble:hover {
  font-size: 63px;
  color: #45b6fe;
  cursor: pointer;
}

.chat-active {
  transition: ease-in-out 0.3s;
  font-size: 63px;
  border-radius: 100px;  
  float: right;
  color: #fff;
  background-color: #45b6fe;
  cursor: pointer;
}

.form {
  padding: 1.5em;
  /* border: 1px solid rgba(0, 221, 206); */
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  /* background: linear-gradient(rgba(0, 221, 206, 0.6), rgba(0, 221, 206, 1)); */
  background: linear-gradient(125deg, rgba(190, 190, 190, 0.6), rgba(190, 190, 190, 0.8));
  /* background: linear-gradient(rgba(0, 157, 147, 0.5), rgba(0, 157, 147, 0.9)); */
  min-height: 350px;
  min-width: 30vw;
  margin-bottom: 35px;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  transition-delay: 0.2s;
}

.form.visible {
  opacity: 1;
}

.form-control {
  display: flex;
  flex-direction: column;
  padding: 0.25em;
}

.close-btn {
  background-color: #ee5151;
  padding: 5px;
  right: 0;
  border-radius: 100px;
  margin-top: -30px;
  color: #fff;
}

.close-btn:hover {
  transition: ease-in-out 0.3s;
  background-color: #b10000;
}

.submit-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 0.25em;
  /* border: 1px solid #fff; */
  border: 1px solid rgb(0, 118, 110);
  border-radius: 15px;
  /* color: #fff; */
  color: rgba(0, 118, 110);
}

.submit-btn:hover {
  transition: ease-in 0.3s;
  color: #fff;
  /* background: rgba(255, 255, 255, 0.3); */
  background: rgba(0, 157, 147);
}

.tech-group {
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transform: translateY(10px);
}

.tech-group.visible {
  opacity: 1;
  transform: translateY(0)
}

.tech-group:hover {
  cursor: pointer;
}

.tech-group:hover .tooltip {
  visibility: visible;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  background: linear-gradient(45deg, rgba(0, 157, 147, 0.2), rgba(0, 157, 147, 0.7));
  border: 1px solid rgba(0, 157, 147);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);  
  z-index: 500;
  padding: 15px;
}

.star {
  color: #fab433;
}

.resume-btn {
  display: flex;
  align-items: center;
  color: rgba(0, 157, 147);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  transition-delay: 0.5s;
  background: #000;
  padding: 5px;
  border-radius: 4px;
  letter-spacing: 1px;
}

.resume-btn.visible {
  opacity: 1;
}

.resume-btn:hover {
  transition: ease-in 0.3s;
  /* color: #45b6fe; */
  color: rgb(0, 215, 200);
}

.typing-animation {
  display: inline-block;
  white-space: nowrap;
}

.text-cursor {
  display: inline-block;
  width: 1ch;
  animation: blink 0.7s steps(1) infinite;
}

@media screen and (max-width: 768px) {
  .navbar {    
    z-index: 1000;
    background: linear-gradient(to bottom, #eeeeee, #f3f3f3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    align-items: center;
  }

  .nav-logo {    
    padding-top: 10px;
    width: 20%;
  }

  .nav-item.active {
    font-size: 12px;
    font-weight: bold;
  }

  .mobile-pic-container {
    display: flex;    
    border: 1px solid rgba(0, 157, 147, 0.65);
    overflow: hidden;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    justify-content: center;
    align-items: center;
  }
  
  .mobile-pic {    
    display: flex;
    width: 150% !important;
    transform: translate(-4%, 25%) scale(2.0);
    border: 1px solid blue;
  }

  body {
    padding-left: 0;
    margin-top: 30px;    
  }

  .intro {
    margin-top: -30px;
  }

  #home {
    position: relative;
  }

  .text-content {  
    margin-top: 125px;
    margin-right: -40px;        
  }

  /* .greeting {    
    margin-top: 0px !important;    
  } */

  .greeting {    
    font-size: 10px;
    letter-spacing: 4px;
  }

  .img-section {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .intro-img {  
    width: 80% !important;
    opacity: 0.5;    
  }
  
  .intro-overlay {    
    top: 45%;    
  }  

  #about {
    overflow-y: auto;    
  }

  .tech-label {
    font-size: 15px;
  }

  .project-card {  
    border: 1px solid #c7c7c7;
    height: 300px;  
    border-radius: 10px;
    position: relative;
    margin-top: 20px;
  }

  .project-description {
    overflow-y: auto;
  }

  .modal {
    width: 75vw;
  }

  .modal-content {
    width: 100%;
    overflow-y: auto;
  }
  
  .project-display-container {
    margin-top: 100px;
  }
  
  .project-images {
    margin-top: 15px;
  }  

  .form {
    width: 75vw;
    margin-right: -5px;
    margin-bottom: -10px;
    padding: 1em;
    background: linear-gradient(125deg, rgba(75, 75, 75, 0.6), rgba(75, 75, 75, 0.8));
  }

  label {
    color: #fff;
  }

  .submit-btn {
    z-index: 100;
    position: relative;
    border: 1px solid #fff;
    color: #fff;
  }
  
  .about-me-pic {
    right: 10vw;
    opacity: 0 !important;
  }
  
  .resume-btn {
    margin-top: 32px;
    margin-left: -20px;
    margin-bottom: 50px;
    color: rgb(0, 157, 147);
    background: transparent;
    /* display: none; */
  }

  .space {
    height: 75px;
  }

  .icon {
    font-size: 25px;
  }
}

@keyframes blink {
  50% {opacity: 0;}
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(10px);}
  to { opacity: 1; transform: translateY(0);}
}

@keyframes expandOverlay {
  0% {
    width: 0;
    height: 1px;
  }
  50% {
    width: 95%;
    height: 1px;
  }
  100% {
    width: 95%;
    height: 45%;
  }
}



